import React from "react"
import Place from "../place"
import { graphql, useStaticQuery } from "gatsby"
import mergeQuery from "../../../../helpers/merge-query"
// import PlacePerson from "../place-person"
// import PlaceLeasingPerson from "../place-leasing-person"
import Footer from "../../../footer"
import CategoryLink from "../../../categories/category-link"
import { StyledContainer } from "./index.style"
import { LinkWrapper } from "../../../availability-table/index.style"

const PlaceList = () => {
  const textTemplate = {
    person: {
      persons: [
        {
          id: 1,
          name: "Julio",
          imageName: "person-1",
          description:
            "With over 15 years of experience managing luxury residential buildings, Julio is an expert in the oversight of day-to-day maintenance operations. Julio and his highly trained staff are readily available to assist residents with any issue and solve any problem."
        },
        {
          id: 2,
          name: "Stephanie",
          imageName: "person-2",
          description:
            "Stephanie has over 10 years’ experience managing luxury residential buildings and is an expert at both anticipating and exceeding residents’ needs. Known for raising the bar in customer service, Stephanie is committed to ensuring an optimal living experience for all residents."
        },
        {
          id: 3,
          name: "Kinley",
          imageName: "person-3",
          description:
            "Kinley is our Assistant Property Manager with over 4 years of experience. Kinley is onboard to assist the General Manager in delivering excellent service to all residents. Kinley will be tasked with the resident coordination and amenity management among other tasks within the building. She has a wide array of skills that will be a huge asset to our building."
        }
      ]
    },
    personLeasing: {
      personsLeasing: [
        {
          id: 1,
          name: "Janelle",
          imageName: "person-leasing-team-1"
        },
        {
          id: 4,
          name: "Monica",
          imageName: "person-leasing-team-4"
        }
      ]
    },

    lobby: {
      blockId: 1,
      dataName: "lobby",
      title: "Peace from the point of entry",
      description: "Forget your worries as you enter Ruby’s bright and tranquil lobby, staffed with 24-hour door person."
    },
    clubLounge: {
      blockId: 2,
      dataName: "clubLounge",
      title: "Gather, connect, and entertain in the natural beauty of the Club Lounge",
      description: "Filled with light and greenery, the Club Lounge offers a relaxed, artful space in which to unwind with friends or mingle with neighbors."
    },
    library: {
      blockId: 3,
      dataName: "library",
      title: "Work, study, or relax in Ruby’s elegant, light-filled library",
      description: "The perfect space for work-from-home and everything in between, complete with warm, organic finishes and cozy corners in which to curl up with your favorite book"
    },
    floorCourtyard: {
      blockId: 4,
      dataName: "floorCourtyard",
      title: "Relax beneath the trees in the natural oasis of the 2nd Floor Courtyard",
      description: "Your own private courtyard, with grilling terraces, in which to refresh and rejuvenate. Breathe fresh air and bask in greenery without stepping outside your building."
    },
    fitnessUpper: {
      blockId: 5,
      dataName: "fitnessUpper",
      title: "Move your body and quiet your mind in the Upper Level Fitness Room",
      description: "Distinguished by floor-to-ceiling windows and equipped with state-of-the-art cardiovascular equipment to elevate your heartrate and your mood."
    },
    fitnessLower: {
      blockId: 6,
      dataName: "fitnessLower",
      title: "Build strength in the serenity of the Lower Level Fitness Room",
      description: "Complete with all the machines and equipment you need to tone and strengthen, you can ditch your gym membership for the comfort of home."
    },
    roofRestourant: {
      blockId: 7,
      dataName: "roofRestourant",
      title: "Cookout while you look out from the South Tower Grill Terrace",
      description: "Everything you need to throw a summer barbecue or grill up dinner while you gaze over the city can be found on the terrace, a private, peaceful space to experience the best of al fresco dining."
    },
    pool: {
      blockId: 8,
      dataName: "pool",
      title: "Float above the city and soak up the sun in Ruby's rooftop pool",
      description: "With panoramic views of the Hudson River and Manhattan skyline, Ruby’s rooftop swimming pool is the perfect retreat from which to unplug, unwind, and refresh."
    },
    rooftopLounge: {
      blockId: 9,
      dataName: "rooftopLounge",
      title: "Experience the beauty of the city from the Roof Lounge",
      description: "Fresh open air and sparkling skyline views provide the ideal setting from which to share a drink or take in a sunset."
    },
    cam: {
      blockId: 10,
      dataName: "cam",
      title: "Play, create, and imagine in the Children's Room",
      description: "A warm, whimsical space in which children can engage in creative play and share activities with friends."
    }
  }

  const query = useStaticQuery(graphql`
    query {
      person: allFile(
        filter: { relativeDirectory: { eq: "amenities/person" } }
      ) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      personLeasing: allFile(
        filter: { relativeDirectory: { eq: "amenities/person-leasing" } }
      ) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }

      lobby: allFile(
        filter: { relativeDirectory: { eq: "amenities/lobby" } }
        sort: { fields: [name], order: ASC }
      ) {
        edges {
          node {
            name
            id
            childImageSharp {
              fluid(maxWidth: 2048, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      clubLounge: allFile(
        filter: { relativeDirectory: { eq: "amenities/club-lounge" } }
        sort: { fields: [name], order: ASC }
      ) {
        edges {
          node {
            name
            id
            childImageSharp {
              fluid(maxWidth: 2048, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      library: allFile(
        filter: { relativeDirectory: { eq: "amenities/library" } }
        sort: { fields: [name], order: ASC }
      ) {
        edges {
          node {
            name
            id
            childImageSharp {
              fluid(maxWidth: 2048, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      floorCourtyard: allFile(
        filter: { relativeDirectory: { eq: "amenities/floor-courtyard" } }
        sort: { fields: [name], order: ASC }
      ) {
        edges {
          node {
            name
            id
            childImageSharp {
              fluid(maxWidth: 2048, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      fitnessUpper: allFile(
        filter: { relativeDirectory: { eq: "amenities/fitness-upper" } }
        sort: { fields: [name], order: ASC }
      ) {
        edges {
          node {
            name
            id
            childImageSharp {
              fluid(maxWidth: 2048, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      fitnessLower: allFile(
        filter: { relativeDirectory: { eq: "amenities/fitness-lower" } }
        sort: { fields: [name], order: ASC }
      ) {
        edges {
          node {
            name
            id
            childImageSharp {
              fluid(maxWidth: 2048, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      roofRestourant: allFile(
        filter: { relativeDirectory: { eq: "amenities/roof-restourant" } }
        sort: { fields: [name], order: ASC }
      ) {
        edges {
          node {
            name
            id
            childImageSharp {
              fluid(maxWidth: 2048, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      pool: allFile(
        filter: { relativeDirectory: { eq: "amenities/pool" } }
        sort: { fields: [name], order: ASC }
      ) {
        edges {
          node {
            name
            id
            childImageSharp {
              fluid(maxWidth: 2048, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      rooftopLounge: allFile(
        filter: { relativeDirectory: { eq: "amenities/rooftop-lounge" } }
        sort: { fields: [name], order: ASC }
      ) {
        edges {
          node {
            name
            id
            childImageSharp {
              fluid(maxWidth: 2048, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      cam: allFile(
        filter: { relativeDirectory: { eq: "amenities/cam" } }
        sort: { fields: [name], order: ASC }
      ) {
        edges {
          node {
            name
            id
            childImageSharp {
              fluid(maxWidth: 2048, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const merged = mergeQuery(query, textTemplate)

  const [person, personLeasing, ...rest] = merged

  return (
    <>
      <StyledContainer>
        <Place content={rest} />
        {/* <PlacePerson content={person} /> */}
        {/* <PlaceLeasingPerson content={personLeasing} /> */}
        <LinkWrapper>
          <CategoryLink link={"/neighborhood"} title={"Neighborhood"} />
        </LinkWrapper>
      </StyledContainer>
      <Footer />
    </>
  )
}

export default PlaceList
